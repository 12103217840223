<template>
  <div>
    <h1 class="text-base font-bold text-gray-type-2">My profile</h1>
    <div class="mt-12">
      <img
        :src="form.avatar || user.avatar"
        v-if="form.avatar || user.avatar"
        class="object-cover w-20 h-20 rounded-full"
      />
      <svg v-else width="80" height="80" xmlns="http://www.w3.org/2000/svg">
        <g fill-rule="nonzero" fill="none">
          <circle fill="#D1D6D4" cx="40" cy="39.771" r="39.114" />
          <path
            d="M54.286 35.629V29.2a14.286 14.286 0 00-7.2-12.486 14.286 14.286 0 00-14.286 0A14.286 14.286 0 0025.714 29.2v6.429A12.629 12.629 0 0032.03 46.57a16.2 16.2 0 0016.171 0 12.629 12.629 0 006.086-10.942z"
            fill="#F3F7F5"
          />
          <path
            d="M40 78.857a38.971 38.971 0 0026.914-10.771 31.429 31.429 0 00-53.828 0A38.971 38.971 0 0040 78.857z"
            fill="#F3F7F5"
          />
        </g>
      </svg>
    </div>
    <div class="mt-8 font-medium text-green-300 cursor-pointer">
      <span v-if="uploadingAvatar">Processing...</span>
      <span v-else @click="$refs.avatar.click()">Change Photo</span>
    </div>
    <form ref="avatar-upload-form">
      <input type="file" ref="avatar" @change="uploadFile" class="hidden" />
    </form>
    <form class="w-full mt-8 lg:w-1/3" @submit.prevent="updateProfile">
      <div class="">
        <label class="text-xs font-medium text-gray-type-3" for="company-name"
          >Company name</label
        >
        <div class="mt-2">
          <input
            v-model="$v.form.corporate_name.$model"
            type="text"
            id="company-name"
            class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 text-gray-type-2 bg-gray-type-4 focus:outline-none"
          />
          <span
            class="text-xs font-bold text-red-400"
            v-if="!$v.form.corporate_name.required && $v.form.corporate_name.$error"
            >Please enter your company name</span
          >
        </div>
      </div>
      <div class="mt-6">
        <label class="text-xs font-medium text-gray-type-3" for="email-address"
          >Email address</label
        >
        <div class="mt-2">
          <input
            v-model="$v.form.email.$model"
            type="email"
            id="email-address"
            class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 text-gray-type-2 bg-gray-type-4 focus:outline-none"
            placeholder="sample@mail.com"
          />
          <span
            class="text-xs font-bold text-red-400"
            v-if="!$v.form.email.required && $v.form.email.$error"
            >Please enter your email address</span
          >
        </div>
      </div>
      <div class="mt-6">
        <label class="text-xs font-medium text-gray-type-3" for="phone"
          >Phone number</label
        >
        <div class="mt-2">
          <input
            v-model="$v.form.phone.$model"
            type="text"
            id="phone"
            class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 text-gray-type-2 bg-gray-type-4 focus:outline-none"
            placeholder="0706 245 1198"
          />
          <div
            class="text-xs font-bold text-red-400"
            v-if="!$v.form.phone.required && $v.form.phone.$error"
          >
            Please enter a valid phone number
          </div>
          <div
            class="text-xs font-bold text-red-400"
            v-if="$v.form.phone.phoneNumber && !$v.form.phone.minLength"
          >
            Phone number must be at least
            {{ $v.form.phone.$params.minLength.min }} digits
          </div>
        </div>
      </div>
      <button
        type="submit"
        class="block w-full h-12 py-4 mt-12 font-bold text-center text-white rounded focus:outline-none"
        :class="processing ? 'bg-green-300' : 'bg-green-type-1'"
        :dislabed="processing"
      >
        {{ processing ? "Processing..." : "Save Changes" }}
      </button>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required, minLength } from "@vuelidate/validators";

const phoneNumber = (value) => {
  return /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(
    value
  );
};

export default {
  data() {
    return {
      form: {},
      avatar: "",
      processing: false,
      errorMessage: "",
      uploadingAvatar: false,
    };
  },
  validations() {
    return {
      form: {
        email: {
          required,
        },
        corporate_name: {
          required,
        },
        phone: {
          required,
          phoneNumber,
          minLength: minLength(11),
        },
      },
    };
  },
  mounted() {
    this.form = { ...this.user };
  },
  watch: {
    user() {
      this.form = { ...this.user };
    }
  },
  computed: {
    ...mapGetters("auth", ["user"])
  },
  methods: {
    updateProfile() {
      this.$v.form.$touch();

      if (this.processing || this.$v.form.$error) {
        return;
      }

      this.processing = true;

      this.$store
        .dispatch(
          "auth/updateUser", {
            email: this.form.email,
            corporate_name: this.form.corporate_name,
            phone: this.form.phone,
          }
        )
        .then(() => {
          this.$toast.success("Profile updated successfully.");
        })
        .catch(() => {
          this.$toast.error("Profile updated failed, please try again.");
        })
        .finally(() => (this.processing = false));
    },
    uploadFile() {
      this.file = this.$refs.avatar.files[0];

      const reader = new FileReader();

      reader.addEventListener("load", (fileLoadedEvent) => {
        const avatar = fileLoadedEvent.target.result;

        this.uploadingAvatar = true;

        this.$store
          .dispatch("auth/updateAvatar", { avatar })
          .then(() => {
            this.$toast.success("Avatar updated successfully.");
          })
          .catch(() => {
            this.$toast.error("Avatar update failed, please try again.");
          })
          .finally(() => (this.uploadingAvatar = false));
      });

      reader.readAsDataURL(this.file);
    },
  },
};
</script>